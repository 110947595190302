import whc from './imgs/whc.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p className='test-font'>*** UNDER CONSTRUCTION ***</p>
        <p>The Honey Bees are at work...website coming soon...</p>
        <img src={whc} className="App-logo" alt="logo" />
        <p>
        "For so work the honey bees, creatures that by a rule in nature teach the act of order to a peopled kingdom." - William Shakespeare
        </p>
      </header>
    </div>
  );
}

export default App;
